<template>
  <div class="wiki-report">

    <div class="wr-body">

      <div class="wr-left">
        <div class="wrl-title">
          <div class="wrl-title-name">{{ data && data.labels[0].name }}</div>
          <div class="wrl-title-more">
            <div style="border-bottom: 1px solid rgba(24, 144, 255, 1)" @click="goMediaReportList">更多</div>
            <div> ></div>
          </div>
        </div>
        <router-link
          v-if="data&&data.labels[0].objs.length>=3"
          :to="data&&data.name==='媒体报道和头条资讯'?{path:'/home/mediaReportDetail',query:{id:item.id}}:{path:'/home/knowledgeDetail',query:{id:item.id}}"
          target="_blank"
          class="wrl-main"
          v-for="item in data&&data.labels[0].objs.length>3?data.labels[0].objs.slice(0,3):data.labels[0].objs"
          :key="item.id"
        >
          <img style="border-radius: 4px" width="80" height="80" :src="item.pic" />
          <div class="wrl-main-right">
            <div class="wrl-main-right-title ellipse-1">{{ item.name }}</div>
            <div class="wrl-main-right-des ellipse-2">{{ item.introduction }}</div>
          </div>

        </router-link>
      </div>

      <div class="wr-right">
        <div class="wrl-title">
          <div class="wrl-title-name">
            {{ data && data.labels[1].name }}
          </div>
          <div class="wrl-title-more">
            <div style="border-bottom: 1px solid rgba(24, 144, 255, 1)" @click="goBaike">更多</div>
            <div> ></div>
          </div>
        </div>
        <router-link
          :to="data&&data.name==='媒体报道和头条资讯'?{path:'/home/knowledgeDetail',query:{id:item.id}}:{path:'/home/sciencePolicyDetail',query:{id:item.id}}"
          target="_blank"
          class="wrr-list"
          v-for="(item,index) in data&&data.labels[1].objs.length>6?data.labels[1].objs.slice(0,6):data.labels[1].objs"
          :key="index">
          <div class="wrr-list-fontA" style="color:rgba(24, 144, 255, 1) ">·</div>
          <div style="flex: 1" class="wrr-list-fontA ellipse-1">{{ item.name }}</div>
          <div class="wrr-list-fontB">{{ item.createdTime }}</div>
        </router-link>
      </div>

    </div>


  </div>
</template>

<script>
import {
  getIndexInformationList
} from "../../../plugins/api/indexApi";

export default {
  name: "wikiAndReport",
  props: {
    data: Object
  },
  data() {
    return {};
  },
  methods: {
    //根据文章分类编号获取文章列表
    goMediaReportList() {
      if (this.getType(this.data && this.data.name)) {
        this.$router.push({
          path: "/home/media-report"
        });
      }else {
        this.$router.push({
          path: "/home/baike",
        });
      }

    },
    goBaike() {
      if (this.getType(this.data && this.data.name)) {
        this.$router.push({
          path: "/home/baike",
          query: {
            tab: 1
          }
        });
      }else {
        this.$router.push({
          path: "/home/policy",
        });
      }

    },
    getType(name) {
      if (name === "媒体报道和头条资讯") {
        return true;
      }
      return false;
    }
  }


};
</script>

<style>
.wiki-report {
  background-color: rgba(247, 248, 250, 1);
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 10px;
}

.wr-body {
  display: flex;
  flex-direction: row;
  background-color: rgba(247, 248, 250, 1);
}


.wr-left {
  display: flex;
  flex-direction: column;
  width: 690px;
  border-radius: 4px;
  height: 370px;
  overflow: hidden;
  background-color: #FFFFFF;
}

.wrl-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid rgba(229, 232, 237, 1);
}

.wrl-title-name {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

.wrl-title-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  cursor: pointer;
}

.wrl-main {
  display: flex;
  flex-direction: row;
  height: 80px;
  margin-top: 20px;
  padding: 0 20px;
}

.wrl-main-right {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
}

.wrl-main-right-title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.wrl-main-right-des {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.wr-right {
  height: 370px;
  width: 480px;
  background-color: #FFFFFF;
  margin-left: 30px;
  border-radius: 4px;
}

.wrr-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  cursor: pointer;
}

.wrr-list-fontA {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin-right: 2px;
}

.wrr-list-fontB {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  flex-shrink: 0;
}
</style>
